import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toDate} from "@core/utils/filter";

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    { key: 'warehouse_id', label: '仓库'},
    { key: 'company_id', label: '货主'},
    { key: 'code', label: '商品69码'},
    { key: 'name', label: '商品名称'},
    { key: 'label', label: '标签'},
    { key: 'qty', label: '库存数量', sortable: true },
    // { key: 'productcode', label: '69码', },
    // { key: 'productunit', label: '单位' },
    // { key: 'boxquantity', label: '单箱数量', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    // { key: 'stock_no', label: '编号', sortable: true },
    // { key: 'location_fullname', label: '库位', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'batch_no', label: '批次', sortable: true },
    // { key: 'is_gift', label: '赠品', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    { key: 'all_cost', label: '库存金额', sortable: true },
    // { key: 'qty_lock', label: '锁库数量', sortable: true },
    // { key: 'product_cost', label: '商品成本', sortable: true },
    // { key: 'tax_rate', label: '税率', sortable: true },
    // { key: 'cost', label: '最终成本', sortable: true },
    { key: 'status', label: '状态'},
    // { key: 'add_time', label: '时间', sortable: true },
    // { key: 'creator', label: '入库人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKeyWare = ref('')
  const searchKeyName = ref('')
  const supplier_id = ref('')
  const PUser_id = ref('')
  const orderBy = ref('stock_id')
  const isSortDirDesc = ref(true)
  const click = ref(0)
  const allCost = ref(0)
  const code = ref('')
  const product_category_id = ref('')
  const negativeStock = ref(0)
  const stockIds = ref('')
  const purchaseTeamId = ref({})
  const warehouseSelected = ref([])
  const productionDate = ref('')
  const productLabelId = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, click], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const warehouseArray = []
    warehouseSelected.value.forEach((value, index, array) => {
      warehouseArray.push(value.value)
    })
    store
      .dispatch('stock/searchGroupByWarehouseIdAndCompanyIdAndStatusAndProductId', {
        search_key_ware: warehouseArray.join(","),
        search_key_name: searchKeyName.value,
        supplier_id: supplier_id.value,
        PUser_id: PUser_id.value,
        code:code.value,
        start: start.value,
        limit: limit.value,
        order_by: 'product_id',
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        productId:config.productId,
        orderBys: orderBy.value,
        orderDescs: isSortDirDesc.value === true ? 'desc' : '',
        product_category_id: product_category_id.value,
        negativeStock: negativeStock.value,
        stockIds: stockIds.value,
        purchaseTeamId: purchaseTeamId.value==null?null:purchaseTeamId.value.value,
        productionDate:productionDate.value,
        productLabel:productLabelId.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        allCost.value = data.ext.allCost
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const searchALlList = (ctx, callback) => {
    store
      .dispatch('stock/searchAllInfo', {
        search_key_ware: searchKeyWare.value,
        search_key_name: searchKeyName.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    searchList,
    searchALlList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKeyWare, searchKeyName,supplier_id, PUser_id,code,product_category_id,negativeStock,stockIds,purchaseTeamId,warehouseSelected,productionDate,productLabelId,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    click,
    allCost,
    // Extra Filters
  }
}
